*
{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html,
body
{
    overflow: hidden;
}

.experience
{
    position: fixed;
    width: 100vw;
    height: 100vh;
}

.credits
{
    position: absolute;
    bottom: 20px;
    right: 20px;
    color: #ffffff;
    text-align: right;
    font-family: 'Roboto', sans-serif;
}

.credits a
{
    color: inherit;
}

.tp-dfwv{
    display:none;
}